<template>
  <v-dialog :value="open" persistent max-width="600">
    <v-card>
      <v-card-title>
        <v-icon left>mdi-exit-run</v-icon>
        Kiléptetés
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />

      <v-card-text class="mt-4">
        <v-row>
          <v-col>
            <div class="font-weight-bold">Név</div>
            <div class="text-body-1">{{ form.name }}</div>
          </v-col>
          <v-col>
            <div class="font-weight-bold">Zóna</div>
            <div class="text-body-1">{{ value.zone_name || '-' }}</div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-text class="mt-4">
        <v-row>
          <v-col>
            <v-autocomplete
              label="Kilépés helye"
              v-model="form.exit_gate_id"
              :items="gates"
              filled
              item-text="name"
              item-value="element_id"
              :hide-details="!errors.exit_gate_id"
              :error-messages="errors.exit_gate_id"
              dense
            />
          </v-col>
          <v-col>
            <v-btn color="primary" depressed block x-large @click="exit" :loading="loading" :disabled="!form.exit_gate_id">Kiléptetés</v-btn>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: [Object, Boolean],
      default: false,
    },
  },

  data() {
    return {
      form: {},
      errors: {},
      loading: false,
    };
  },

  watch: {
    value: {
      handler() {
        this.form = this.value;
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    async exit() {
      try {
        this.loading = true;
        this.errors = {};
        const response = await this.$http.post('occupants/exit', this.form);
        if (Object.keys(response.errors).length) {
          this.errors = response.errors;
        } else {
          this.$dialog.notify.success('Sikeres mentés!');
          this.close();
          this.$emit('success');
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    close() {
      this.$emit('input', false);
    },
  },

  computed: {
    open() {
      return !!this.value;
    },
  },

  asyncComputed: {
    gates: {
      async get() {
        const response = await this.$http.post('gates/list', { reader_direction: 'O', limit: 9999 });
        if (response.gates.length === 1) {
          this.form.exit_gate_id = response.gates[0].element_id;
        }
        return response.gates;
      },
      default: [],
    },
  },
};
</script>
